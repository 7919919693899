@keyframes slide {
    0% {
        transform: translateX(34px);
    }

    100% {
        transform: translateX(-4500px);
    }
}

@keyframes show-up {
    0% {
        transform: translateY(20px);
        opacity: 0;
    }

    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}

@keyframes show-cases {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-100%);
    }
}

$color-secondary: #DFDBD5;
$color-primary: #212121;

body { 
    padding : 0;
    margin : 0;
    height: 100vh;
    width: 100%;
    background-color: $color-secondary;
    overflow-x: hidden;
    @import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');
    font-family: 'Manrope', sans-serif;
}

.App { 
    height: 100vh;
    width: 100%;
    padding-top: 110px;
    color: $color-primary;

    a.link {
        font-size: 12px;
        color: inherit;
        display: inline-block;
        text-decoration: none;
        transition: all 0.2s;
        padding: 5px 0;
        position: relative;

        &:after {
            content: '';
            border: solid;
            border-color: inherit;
            border-width: 0 0 1px 0;
            display: block;
            top: 5px;
            position: relative;
            transition: all 0.5s;
            width: 100%;
        }

        &:before {
            content: '';
            width: 4.5px;
            height: 7.36px;
            position: absolute;
            right: 0;
            bottom: -3px;
            transition: right 0.5s;
            background-image: url('../../img/arrow.svg');
            opacity: 0;
        }

        &:hover:after {
            width: 120%;
        }

        &:hover:before {
            right: -21%;
            opacity: 1;
        }
    }

    p {
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 0.02em;

        a {
            color: inherit;
            font-weight: bold;
        }

        ul {
            font-style: normal;
            font-weight: 500;
            font-size: 32px;
            line-height: 44px;
            list-style: none;
            padding: 0;
            padding-top: 24px;
            margin: 0;
            border-top: 1px solid $color-primary;

            li {
                margin-bottom: 12px;
                text-transform: uppercase;
            }
        }
    }

    hr {
        width: 80%;
        border-bottom: solid $color-primary;
        margin: 0 auto;
        border-width: 0 0 1px 0;
    }

    img {
        filter: grayscale(100%);
        opacity: 0.2;
        transition: all 0.5s;

        &:hover {
            filter: grayscale(0%);
            opacity: 1;
        }
    }

    .hat {
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        letter-spacing: 0.02em;
    }

    .tag {
        padding: 2px 5px;
        background: $color-primary;
        color: $color-secondary;
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 400;
        margin-left: 5px;
    }

    .menu-languages {
        width: 210px;
        list-style: none;
        display: flex;
        justify-content: space-between;
        padding: 0;

        li {
            display: inline-block;
            border-bottom: 1px solid $color-primary;
            padding: 4px 20px;
            font-size: 12px;
            cursor: pointer;
            transition: all 0.1s;

            &.active,
            &:hover {
                border-bottom: 3px solid $color-primary;
            }
        }
    }

    header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 40px;
        width: 100%;
        box-sizing: border-box;
        animation: show-up 2s ease-out 0.5s 1 normal none;
        animation-fill-mode: both;
        position: fixed;
        top: 0;
        background: $color-secondary;
        z-index: 2;

        .btn-back {
            position: relative;
            left: 0;
            transition: all 0.5s;
            cursor: pointer;

            &:after {
                content: '';
                position: absolute;
                top: -20px;
                left: -20px;
                width: 100%;
                height: 100%;
                padding: 20px;
            }

            &:hover {
                left: -15px;
            }
        }
        
        span, a {
            font-size: 12px;
            color: inherit;
            text-decoration: none;
            vertical-align: middle;

            strong {
                font-weight: 500;
                margin-left: 40px;
                vertical-align: middle;
                text-transform: uppercase;
                letter-spacing: 0.02em;

                svg {
                    margin-left: 50px;
                }
            }
        }
    }

    .cover {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        text-align: right;
        margin-right: 40px;
        height: 75vh;
        background-color: $color-secondary;
        padding-bottom: 20px;
        animation: show-up 2s ease-out 0.5s 1 normal none;
        animation-fill-mode: both;

        .cover-name {
            font-weight: 400;
            font-size: 140px;
            line-height: 89.6%;
            margin-bottom: 20px;
            text-transform: uppercase;
            transition: all 0.5s;
            word-break: break-all;
            width: 35%;
            color: inherit;

            // &.interaction {
            //     min-height: 248px;

            //     &:hover {
            //         width: 100%;
            //     }
            // }
        }

        p {
            margin: 0;
            text-transform: uppercase;
        }

        .menu-languages {
            display: none;
        }
    }

    .cover-avatar {
        margin: 0 auto;
        overflow: hidden;
        height: 90vh;
        width: 35%;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 9;
        background-color: inherit;

        img {
            width: auto;
            height: 100%;
            position: relative;
            z-index: 8;
        }
    }

    .cover-img {
        margin: 0 20px;
        overflow: hidden;
        min-height: 120vh;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 9;
        background-color: #D4D0C9;

        &.profile {
            min-height: 70vh;
            height: 70vh;
        }

        p {
            position: absolute;
            left: 25px;
            bottom: 5px;
            z-index: 10;
            font-size: 12px;
            opacity: 0.5;
        }

        img {
            width: 100%;
            height: auto;
            position: relative;
            z-index: 8;
        }

        &.player {
            height: 120vh;
            margin: -3% 20px;
            background-color: transparent;
        }

        video {
            width: 70%;
            height: auto;
            border: 20px solid $color-secondary;
        }

        &.proto {
            background-color: #D4D0C9;
            margin-bottom: 150px;
            min-height: 140vh;
        }

        &.iphone {
            background-color: #D4D0C9;
            margin-bottom: 150px;
            margin-top: 150px;
            min-height: 140vh;
            height: auto;
        }

        iframe {
            width: 100%;
            height: 100%;
        }
    }

    .mockup {
        height: 700px;
        width: auto;
        justify-content: center;
        position: relative;

        img {
            opacity: 1;
            height: 100%;
            width: auto;
        }

        video {
            height: 650px;
            top: 50%;
            width: auto;
            position: absolute;
            top: 50%;
            margin-top: -325px;
            left: 50%;
            margin-left: -151px;
            border: none;
        }
    }

    .display {
        display: flex;
        justify-content: space-between;
        width: 100%;
        overflow: hidden;
        position: relative;
        z-index: 9;
        text-transform: uppercase;
        margin-top: -40px;

        span {
            font-size: 210px;
            margin-right: 8px;
            animation: 30s linear 0s infinite normal none running slide;
            will-change: transform;

            &:nth-child(1),  
            &:nth-child(6) {
                font-weight: 500;
            }

            &:nth-child(2),
            &:nth-child(7) {
                opacity: 0.6;
                font-weight: normal;
            }

            &:nth-child(3),
            &:nth-child(8) {
                opacity: 0.4;
                font-weight: 300;
            }

            &:nth-child(4),
            &:nth-child(9) {
                opacity: 0.4;
                font-weight: 100;
            }

            &:nth-child(5),
            &:nth-child(10) {
                opacity: 0.2;
                font-weight: 100;
            }
        }
    }

    .presentation {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: 150px;

        p {
            padding-left: 55%;
            width: 350px;
        }
    }
    
    .companies {
        padding-bottom: 200px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding-left: 55%;
        margin-top: 150px;

        .hat {
            text-align: left;
            margin-bottom: 32px;
        }

        &_logos {
            width: 480px;
            margin-left: -30px;
            
            a {
                opacity: 1;
                transition: all 0.5s;
                width: 120px;
                height: 52px;
                display: inline-block;
                text-align: center;

                svg {
                    vertical-align: middle;
                }
            }

            a:hover {
                opacity: 0.5;
            }
        }
    }

    .cases {
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 9;
        margin-bottom: 150px;

        figure {
            img, video {
                width: auto;
                height: 100%;
            }
        }

        .case-one {
            position: relative;
            height: 95vh;
            display: flex;
            align-items: center;
            flex-direction: row;
            margin: 0 20px;

            &:hover {
                img {
                    filter: grayscale(0%);
                    opacity: 1;
                }
            }

            figure {
                height: 100%;
                margin: 0;
                width: 50%;
                overflow: hidden;
                text-align: right;
                background-color: $color-secondary;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                flex-direction: column;
            }

            &:hover figure img, &:hover figure video {
                animation: show-cases 0.5s ease-out 1s 1 normal none;
                animation-fill-mode: both;
            }

            .case-title {
                display: flex;
                flex-direction: column;
                position: relative;
                z-index: 2;
                background: $color-primary;
                width: 50%;
                height: 100%;
                color: $color-secondary;
                padding: 40px;
                box-sizing: border-box;
                text-align: right;

                &_hat {
                    font-weight: normal;
                    font-size: 24px;
                    line-height: 33px;
                    letter-spacing: 0.02em;
                    margin-bottom: 20px;
                    text-transform: uppercase;

                    .dash {
                        display: inline-block;
                        vertical-align: middle;
                        width: 100px;
                        border-bottom: 1px solid $color-secondary;
                        margin: 0 16px;
                    }
                }

                &_postname {
                    font-weight: 500;
                    font-size: 100px;
                    line-height: 1.1em;
                    letter-spacing: 0.02em;
                    color: $color-secondary;
                    text-transform: uppercase;
                }

                &_company {
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 22px;
                    letter-spacing: 0.02em;
                    vertical-align: middle;
                    position: absolute;
                    bottom: 40px;

                    svg {
                        margin-left: 5px;
                        vertical-align: middle;
                    }
                }

                &_link {
                    position: absolute;
                    left: 50%;
                    bottom: 0;
                    font-weight: 400;
                    font-size: 90px;
                    line-height: 123px;
                    letter-spacing: 0.02em;
                    z-index: 3;
                    background-color: $color-secondary;
                    padding: 28px 70px 38px;
                    transition: padding-right 0.5s;

                    &:hover {
                        padding-right: 125px;
                    }

                    &:before {
                        right: 64px;
                    }

                    &:hover:before{
                        bottom: 30px;
                        right: 68px;
                    }
                }
            }
        }
    }

    .case-slider {
        padding: 20px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-left: 20px;

        .case {
            width: 400px;
            display: flex;
            background-color: $color-secondary;
            position: relative;
            cursor: pointer;
            flex-direction: column;
            text-decoration: none;
            color: inherit;
            cursor: pointer;

            &:hover {
                img {
                    filter: grayscale(0%);
                    opacity: 1;
                }
            }

            figure {
                position: relative;
                height: 400px;
                width: 400px;
                overflow: hidden;
                margin: 0;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-bottom: 8px;
                flex-direction: column;

                img {
                    width: auto;
                    height: 100%;
                }
            }

            &:hover figure img {
                animation: show-cases 0.5s ease-out 2s 1 normal none;
                animation-fill-mode: both;
            }

            &.case-two {
                margin-right: 20px;
                margin-top: 170px;
            }

            &.case-four, &.case-five {
                margin-top: 62px;
            }

            .case-title {
                text-shadow: none;
                transition: all 0.5s;

                &_company {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 22px;
                    letter-spacing: 0.02em;
                    padding: 20px 25px 0 0;
                    background-color: $color-secondary;
                    display: flex;
                    align-items: center;

                    .dash {
                        display: inline-block;
                        vertical-align: middle;
                        width: 50px;
                        border-bottom: 1px solid $color-primary;
                        margin: 0 16px;
                    }
                }

                &_postname {
                    font-weight: 500;
                    font-size: 48px;
                    line-height: 1.2em;
                    letter-spacing: 0.02em;
                    
                }
            }
        }
    }

    footer {
        min-height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;

        .footer-heading {
            font-weight: normal;
            font-size: 90px;
            line-height: 92px;
            letter-spacing: 0.02em;
            margin-bottom: 68px;
        }

        .footer-links {
            display: flex;
            justify-content: space-between;
            width: 600px;
        }

        p {
            position: absolute;
            bottom: 20px;

            a.link {
                font-size: 16px;
            }
        }
    }

    .container {
        width: 1030px;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        
        .col1 {
            width: 50%;
        }
    
        .col2 {
            width: 50%;
        }
    
        .col6 {
            width: 80%;
        }
    }
    
    .casepage-paragraphy {
        width: 75%;
        padding-bottom: 32px;
    }
    
    .casepage-client {
        display: flex;
        flex-direction: column;
        text-align: right;
        justify-content: flex-end;
        margin: 150px 0 165px;
        font-size: 12px;
    
        a {
            color: inherit;
        }
    }
    
    .casepage-title {
        font-weight: 500;
        font-size: 68px;
        line-height: 1.1em;
        letter-spacing: 0.02em;
        color: inherit;
        margin: 150px 0;
        text-transform: uppercase;
    
        &_hat {
            font-weight: normal;
            font-size: 24px;
            line-height: 33px;
            letter-spacing: 0.02em;
            margin-bottom: 10px;
            display: block;
    
            .dash {
                display: inline-block;
                vertical-align: middle;
                width: 100px;
                border-bottom: 1px solid;
                border-color: inherit;
                margin: 0 16px;
            }
        }

        &_postname {
            margin-left: -4px;
            display: inline-block;
        }
    }
    
    .casepage-deliverables {
        margin-bottom: 46px;
    
        &_hat {
            font-weight: normal;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: 0.02em;
            display: block;
            margin-bottom: 10px;
        }
    
        &_text {
            font-weight: 600;
            font-size: 40px;
            line-height: 48px;
            letter-spacing: 0.02em;
            display: block;
        }
    
        &:last-of-type {
            margin-bottom: 0;
        }
    }
    
    .case-gallery {
        // overflow: scroll;
        margin: 150px 20px 64px;
        height: 120vh;
        display: flex;
        flex-direction: row;
        position: relative;
        z-index: 9;
    
        &_col1 {
            width: 60%;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #D4D0C9;
    
            &:first-child {
                margin-right: 20px;
            }
        }
    
        &_col2 {
            width: 40%;
            display: flex;
            flex-direction: column;
            overflow: hidden;
    
            .case-gallery_photo:first-of-type {
                margin-bottom: 20px;
            }
        }
    
        // &_slider {
        //     padding: 0 20px;
        //     display: flex;
        //     width: fit-content;
        // }
    
        &_photo {
            overflow: hidden;
            width: auto;
            height: 100%;
            margin: 0;display: flex;
            justify-content: center;
    
            img, video {
                width: auto;
                height: 100%;
            }
        }
    }
    
    .more-cases {
        width: 1030px;
        margin: 150px auto; 
    
        ul {
            padding: 0;
            margin: 0;
            display: flex;
            list-style: none;
    
            h3 {
                font-style: normal;
                font-weight: 500;
                font-size: 28px;
                line-height: 32px;
            }
    
            li {
                width: 33%;
    
                p {
                    font-size: 14px;
                    width: 60%;
                }
            }
        }
    }
    
    .articles {
        display: flex;
        flex-direction: column;
        align-items: center;
    
        .article {
            padding: 68px 0;
            width: 1030px;
            display: flex;
            flex-direction: row;
            align-items: center;
            border-bottom: 1px solid;
            border-color: inherit;
            position: relative;
            z-index: 1;
            cursor: pointer;
            color: inherit;
            text-decoration: none;
    
            &:first-child {
                border-top: 1px solid;
                border-color: inherit;
            }
    
            figure {
                margin: 0;
                width: 192px;
                height: 145px;
                margin-right: 32px;
                overflow: hidden;
                display: flex;
                justify-content: center;
    
                img {
                    width: auto;
                    height: 100%;
                    opacity: 0.5;
                }
            }
    
            .article-title {
                width: 350px;
                display: flex;
                flex-direction: column;
    
                &_hat {
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 22px;
                    letter-spacing: 0.02em;
                    margin-bottom: 8px;
                    text-transform: uppercase;
                }
    
                &_postname {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 32px;
                    line-height: 1.2;
                }
            }
    
            .link-arrow {
                position: absolute;
                right: 0;
                transition: all 0.5s;
            }
    
            &:hover {
                .link-arrow {
                    right: -40px;
                }
    
                img {
                    opacity: 1;
                    filter: grayscale(0);
                }
            }
        }
    }
    
    .colaborators {
        margin: 150px auto 100px;
        line-height: 28px;
        letter-spacing: 0.02em;
    
    
        ul {
            list-style: none;
            margin-bottom: 60px;
            padding-left: 0;
            margin-top: 0;
    
            li {
                font-weight: normal;
            }
        }
    
        img {
            border-radius: 100%;
            overflow: hidden;
            width: 32px;
            height: 32px;
            opacity: 1;
            margin-left: 10px;
            vertical-align: middle;
        }
    
    }
    
    .process {
        width: 1030px;
        margin: 0 auto 150px;
        display: flex;
        flex-direction: row;
    
        &_step {
            border-top: 1px solid;
            border-color: inherit;
            color: inherit;
            margin-right: 10px;
            padding: 48px 48px 0px 0px;
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            width: 50%;
    
            &_number {
                width: 100px;
                height: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 100%;
                background: $color-primary;
                color: $color-secondary;
                margin-bottom: 25px;
                font-weight: 400;
            }
    
            &_title {
                font-style: normal;
                font-weight: 600;
                font-size: 26px;
                line-height: 38px;
                letter-spacing: 0.02em;
                margin: 0;
                margin-bottom: 12px;
            }
    
            p {
                font-size: 14px;
            }
    
            // &:nth-child(1) {
            //     opacity: 0.5;
            // }
    
            // &:nth-child(2) {
            //     opacity: 0.65;
            // }
    
            // &:nth-child(3) {
            //     opacity: 0.8;
            // }
        }
    }
}

// ARTICLE PAGE

@import 'mobile.scss';