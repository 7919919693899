@media only screen and (max-width: 767px) {

    body {
        overflow-x: hidden;
    }

    .App {
        padding-top: 90px;

        img {
            opacity: 1;
            filter: grayscale(0%);
        }

        header {
            padding: 30px;
            background-color: #DFDBD5;

            strong {
                display: none;
            }

            .mini-logo {
                display: block;
            }

            .link {
                position: relative;
                right: 0;
            }
        }

        .menu-languages {
            width: 160px;
        }

        .menu-languages li {
            padding: 4px 12px;
        }

        .cover {
            padding-bottom: 20px;
            height: 70vh;
            margin-right: 30px;

            .cover-name {
                word-break: break-all;
                font-size: 85px;
                width: 70%;
            }
        }

        .cover-img {
            height: 35vh;
            min-height: 35vh;
            padding: 0;
            margin: 0 0px;

            &.profile {
                margin: 0 12px;
            }

            p {
                font-size: 10px;
                bottom: 0;
                left: 8px;
                margin: 0;
            }

            &.proto {
                display: none;
            }

            &.player {
                height: 30vh;
                min-height: 30vh;
                margin: -3% 0;

                iframe {
                    width: 100%;
                }
            } 
            img {
                height: 100%;
                width: auto;
            }
        }

        .mockup {
            display: none;
        }

        .presentation {
            justify-content: flex-start;
            padding-top: 0;

            p {
                padding-left: 20px;
                width: 70%;
            }

            ul {
                font-size: 20px;
                font-weight: 600;
            }
        }

        .companies {
            padding-bottom: 50px;
            padding-left: 20px;
            margin-top: 50px;

            &_logos {
                transform: scale(0.8);
                width: initial;
                margin-left: -60px;

                a {
                    width: 100px;
                }
            }
        }

        .cases {
            margin-bottom: 60px;

            .case-one {
                height: auto;
                margin: 0 20px;
                flex-direction: column;

                &:hover figure video,
                &:hover figure img {
                    animation: none;
                }

                figure {
                    height: 340px;
                    width: 100%;
                    border-bottom: 0;
                    box-sizing: border-box;

                    img {
                        height: 100%;
                        width: auto;
                    }
                }

                .case-title_hat {
                    font-size: 16px;
                    margin-bottom: 8px;

                    .dash {
                        width: 50px;
                    }
                }

                .case-title_postname {
                    font-size: 48px;
                    margin-bottom: 40px;
                }

                .case-title {
                    width: 100%;
                    padding: 30px;

                    &_company {
                        position: relative;
                        bottom: initial;
                    }
                }

                .case-title_link {
                    font-size: 28px;
                    width: 50%;
                    text-align: center;
                    padding: 0;
                    font-weight: 600;
                    line-height: 90px;
                    text-decoration: underline;

                    &:after {
                        display: none;
                    }
                }

            }

            .case-slider {
                margin: 20px 0 0;
                padding: 0 20px;
                display: flex;

                .col1 {
                    margin-right: 4%;
                }

                .col1, .col2 {
                    width: 48%;
                }

                .case {
                    width: 100%;

                    figure {
                        height: 220px;
                        width: 100%;
                    }

                    .case-title_company {
                        display: none;
                    }

                    .case-title_postname {
                        font-size: 24px;
                        font-weight: 600;
                    }
                }
            }

            // .case-slider {
            //     flex-direction: column;
            //     margin-left: 0;
            //     padding: 12px;
                
            //     .col2 {
            //         display: flex;
            //         align-items: flex-end;
            //         flex-direction: column;
            //     }

            //     figure {
            //         width: 100%;
            //     }

            //     .case {
            //         width: 250px;
            //         align-items: flex-start;

            //         .dash {
            //             width: 25px;
            //         }

            //         &.case-two {
            //             margin-top: 20px;
            //         }

            //         &.case-three, &.case-four {
            //             margin-top: 0;
            //         }

            //         &.case-three {
            //             left: -60px;
            //         }

            //         &.case-five {
            //             right: 0;
            //         }

            //         .case-title_company {
            //             transform: scale(0.9em);
            //             padding: 10px 15px 10px 0px;
            //         }

            //         margin-bottom: 24px;

            //         figure {
            //             width: 250px;
            //             height: 260px;
            //         }

            //         .case-title_postname {
            //             font-size: 26px;
            //             line-height: 35px;
            //         }
            //     }
            // }
        }

        .more-cases {
            width: 100%;
            margin: 0 auto;
            border-top: 1px solid #212121;

            ul {
                flex-direction: column;
                padding: 0 20px;

                &:first-of-type {
                    padding-top: 20px;
                }

                li {
                    width: 100%;

                    h3 {
                        margin: 20px 0;
                    }

                    p {
                        width: 70%;
                    }
                }
            }
        }

        .articles {
            padding-top: 20px;

            .article {
                padding: 20px;
                width: 100%;
                box-sizing: border-box;

                figure {
                    height: 95px;
                    margin-right: 24px;

                    img {
                        filter: grayscale(0%);
                        opacity: 1;
                    }
                }

                .article-title_hat {
                    font-size: 14px;
                }

                .article-title_postname {
                    font-size: 20px;
                    line-height: 1.2em;
                    font-weight: 600;
                }

                .link-arrow {
                    display: none;
                }
            }
        }

        footer {
            padding: 150px 20px 20px;
            align-items: flex-start;
            height: auto;
            min-height: initial;

            p {
                margin-top: 20px;
                position: relative;
                bottom: initial;
            }

            .footer-links {
                flex-direction: column;
                width: auto;
                margin: 50px 0;

                a {
                    margin-bottom: 20px;
                    font-size: 16px;
                }
            }

            .footer-heading {
                font-size: 70px;
                line-height: 77px;
                margin-bottom: 40px;
            }
        }

        .container {
            width: 100%;
            margin: 0;
            flex-direction: column;
    
            .col1, .col2, .col6 {
                width: 100%;
                padding: 0 20px;
                box-sizing: border-box;
            }
        }
    
        .process {
            flex-direction: column;
            margin: 0 auto;
            width: 100%;
            padding: 0 20px;
            box-sizing: border-box;
    
            &_step {
                height: auto;
                border: 0;
                margin-right: 0;
                margin-bottom: 20px;
                border-bottom: 1px solid;
                border-color: inherit;
                padding: 20px 0;
                width: 100%;
    
                &_title {
                    width: 50%;
                }
    
                &:first-of-type {
                    padding-top: 40px;
                }
    
                p {
                    width: 50%;
                }
            }
        }
    
        .casepage-client {
            margin: 40px 0 0;
        }
    
        .casepage-title_hat {
            font-size: 18px;
            margin-bottom: 0;
    
            .dash {
                width: 25px;
            }
        }
    
        .casepage-title_hat {
            font-size: 12px;
        }
    
        .casepage-title {
            font-size: 48px;
            margin: 20px 0 0;
        }
    
        .casepage-deliverables_text {
            font-size: 34px;
            line-height: 42px;
            width: 80%;
        }
    
        .colaborators {
            margin: 20px 0px 0px;
            flex-direction: column;
            width: 100%;
    
            .col1 {
                margin-bottom: 60px;
            }
    
            span {
                margin-bottom: 15px;
            }
    
            img {
                width: 24px;
                height: 24px;
            }
        }
    
        .case-gallery {
            margin-top: 60px;
            flex-direction: column;
            height: initial;
    
            &_col1 {
                margin-bottom: 20px;
            }
    
            &_col1, &_col2 {
                width: 100%;
            }
    
            .case-gallery_photo {
                width: auto;
                height: 300px;
    
                img {
                    height: 100%;
                    width: auto;
                }
            }
        }
    }
}

