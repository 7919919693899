$theme-color-primary: #ce5353;
$color-secondary: #DFDBD5;

.claro {
    * {
        color: $theme-color-primary;
        border-color: $theme-color-primary;
    }

    a:after {
        border-color: $theme-color-primary;
    }

    svg {
        fill: $theme-color-primary;
    }
    
    .process_step_number {
        background: $theme-color-primary;
        color: $color-secondary;
    }
}
